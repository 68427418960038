// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-news-post-template-tsx": () => import("/opt/build/repo/src/templates/newsPostTemplate.tsx" /* webpackChunkName: "component---src-templates-news-post-template-tsx" */),
  "component---src-templates-standalone-post-template-tsx": () => import("/opt/build/repo/src/templates/standalonePostTemplate.tsx" /* webpackChunkName: "component---src-templates-standalone-post-template-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-archive-tsx": () => import("/opt/build/repo/src/pages/mediaArchive.tsx" /* webpackChunkName: "component---src-pages-media-archive-tsx" */),
  "component---src-pages-news-archive-tsx": () => import("/opt/build/repo/src/pages/newsArchive.tsx" /* webpackChunkName: "component---src-pages-news-archive-tsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

